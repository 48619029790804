import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout, Container } from 'layout';

import BreadCrumbs from 'common/BreadCrumbs';
import ArticleBody from 'components/ArticleBody';
import FeaturedProducts from 'components/FeaturedProducts';
import TargetingSection from 'components/TargetingSection';
import ArticleReferences from 'components/ArticleReferences';
import FeaturedArticles from 'components/FeaturedArticles';
import HeroBanner from 'components/HeroBanner';

import { isNotEmptyArray } from 'utils/array';

import { IPropsArticleAboutStrepsilsPage } from './models';
import './ArticleAboutStrepsilsPage.scss';
import './ShadowArticleAboutStrepsilsPage.scss';

// activeColor is a part of the future ProductFamily functionality
const activeColor = '#FDBD21';

const ArticleAboutStrepsilsPage: FC<IPropsArticleAboutStrepsilsPage> = ({
  pageContext: { breadcrumbs, lang },
  data: {
    articleAboutStrepsils: {
      seo,
      defaultCompositions,
      articleHeroBanner,
      articleTableOfContentsTitle,
      articleBody,
      articleReferenceSection,
      targetingSection,
      featuredArticlesList,
      featuredProductsList,
      createDate,
      updateDate,
    },
    featuredArticlesAboutStrepsilsByLink,
    featuredArticlesAboutStrepsilsByTag,
    featuredArticlesSymptomsByLink,
    featuredArticlesSymptomsByTag,
    featuredArticlesByLink,
    featuredArticlesByTag,
    featuredProductsByLink,
    featuredProductsByTag,
    articleSettings,
  },
}) => (
  <Layout defaultCompositions={defaultCompositions} seo={seo} className="article-about-strepsils">
    <Container>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
    </Container>
    {articleHeroBanner?.length ? (
      <HeroBanner heroBanner={articleHeroBanner} showTitleOnTop />
    ) : null}
    <Container>
      <ArticleBody
        articleBody={articleBody}
        defaultCompositions={defaultCompositions}
        lang={lang}
        articleTableOfContentsTitle={articleTableOfContentsTitle}
        articleSettings={articleSettings}
        createDate={createDate}
        updateDate={updateDate}
      />
    </Container>
    {isNotEmptyArray(featuredArticlesList) ? (
      <FeaturedArticles
        featuredArticlesList={featuredArticlesList}
        featuredArticlesByLink={[
          ...featuredArticlesAboutStrepsilsByLink.nodes,
          ...featuredArticlesSymptomsByLink.nodes,
          ...featuredArticlesByLink.nodes,
        ]}
        featuredArticlesByTag={[
          ...featuredArticlesAboutStrepsilsByTag.nodes,
          ...featuredArticlesSymptomsByTag.nodes,
          ...featuredArticlesByTag.nodes,
        ]}
        dir={defaultCompositions.siteSettings.dir[0]}
        ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
        ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
      />
    ) : null}
    {isNotEmptyArray(featuredProductsList) ? (
      <FeaturedProducts
        featuredProducts={featuredProductsList}
        featuredProductsByLink={featuredProductsByLink.nodes}
        featuredProductsByTag={featuredProductsByTag.nodes}
        productsOrder={featuredProductsList?.[0]?.properties?.selectedProductsIds || []}
        dir={defaultCompositions.siteSettings.dir[0]}
        ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
        ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
        activeColor={activeColor}
      />
    ) : null}
    {isNotEmptyArray(targetingSection) ? (
      <Container>
        <TargetingSection
          targetingSection={targetingSection}
          ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
          ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
          productQuizData={defaultCompositions.productQuiz}
          lang={lang}
        />
      </Container>
    ) : null}
    {articleReferenceSection[0] ? (
      <ArticleReferences articleReferenceSection={articleReferenceSection[0]} />
    ) : null}
  </Layout>
);

export const query = graphql`
  query ArticleAboutStrepsilsPage(
    $link: String = ""
    $lang: String!
    $selectedArticlesLinks: [String]
    $selectedArticlesTag: Int
    $selectedProductsLinks: [String]
    $selectedProductTag: Int
  ) {
    articleAboutStrepsils(link: { eq: $link }) {
      createDate
      updateDate
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      articleHeroBanner {
        ...FragmentHeroBanner
      }
      articleTableOfContentsTitle
      articleBody {
        ...FragmentArticleBody
      }
      articleReferenceSection {
        ...FragmentArticleReference
      }
      targetingSection {
        ...FragmentTargetingSection
      }
      featuredArticlesList {
        ...FragmentFeaturedArticles
      }
      featuredProductsList {
        ...FragmentFeaturedProducts
      }
    }
    featuredArticlesAboutStrepsilsByLink: allArticleAboutStrepsils(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleAboutStrepsilsCardRelated
      }
    }
    featuredArticlesAboutStrepsilsByTag: allArticleAboutStrepsils(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleAboutStrepsilsCardRelated
      }
    }
    featuredArticlesSymptomsByLink: allArticleSymptoms(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleSymptomsCardRelated
      }
    }
    featuredArticlesSymptomsByTag: allArticleSymptoms(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleSymptomsCardRelated
      }
    }
    featuredArticlesByLink: allArticle(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredArticlesByTag: allArticle(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredProductsByLink: allProduct(
      filter: { link: { in: $selectedProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredProductsByTag: allProduct(
      filter: { tags: { elemMatch: { id: { eq: $selectedProductTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    articleSettings(lang: { eq: $lang }) {
      ...FragmentArticleSettings
    }
  }
`;

export default ArticleAboutStrepsilsPage;
